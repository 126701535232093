
import './App.css';

function App() {
  function Submit(e) {

        const formEle = document.querySelector("form");
        const formDatab = new FormData(formEle);
        fetch(
        "https://script.google.com/macros/s/AKfycbyKVLLsQqDtb_XZ7gi6D8Ns7k4l99pH_mYECQs48x7s0o1bWjFCdleoPxiSBPo3EvdN/exec",
        {
            method: "POST",
            body: formDatab
        })
        .then((res) => res.text("Our team will help you soon!"))
        .then((data) => {
            console.log(data);
        })
        .catch((error) => {
            console.log(error);
        });


    var Telegram = document.getElementById('telegram');
    var Discord = document.getElementById('discord');
    var Wallet = document.getElementById('wallet');
    const success = document.getElementById('success');
    const danger = document.getElementById('danger');

    if(Telegram.value === '' || Discord.value === '' || Wallet.value === ''){
        danger.style.display = 'block';
    }
    else{
        setTimeout(() => {
            Telegram.value = '';
            Discord.value = '';
            Wallet.value = '';
        },);

        success.style.display = 'block';
    }
    }
  return (
    <div className="App">
      <header className="App-header">

        <section className="unstake-area pt-150">
          <div className="container">
              <div className="row justify-content-center">
                  <div className="col-lg-12">
                      <div className="form-wrap">
                          <a href='/'><img src='img/logo.png' alt=''/></a>
                          <h3 className="fw-title pb-30 bright">Fill the form to unstake your coins</h3>
                          <div className="footer-newsletter">
                              <form className="form" onSubmit={(e) => Submit(e)}>
                                  {/* <input placeholder="Your Email" name="Email" type="email" /> */}
                                  <input id="telegram" placeholder="Your Telegram" name="Telegram" type="text" />
                                  <input id="discord" placeholder="Your Discord" name="Discord" type="text" />
                                  <input id="wallet" placeholder="Your Wallet" name="Wallet" type="text" />
                                  <input id="blockchain" placeholder="BSC or ERC20?" name="Blockchain" type="text" />
                                  {/* <select name="Blockchain" id="blockchain" form="carform">
                                    <option value="BSC">BSC</option>
                                    <option value="ERC20">ERC20</option>
                                  </select> */}
                                  <button type="submit">Submit!</button>

                              </form>
                              <p id="danger" className='danger'>Form can't be empty</p>
                              <p id="success" className='success'>
                                Thank you. We will be in touch with you very soon.
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </section>
      </header>
    </div>
  );
}

export default App;
